import { useNavigate } from "react-router-dom";
import yellowDiamond from "../media/yellow-diamond.mp4";
import { useRef, useEffect } from "react";
import giaReport from "../media/giareport.webp"

function Collection({showPopupHandler, hidePopupHandler}){

    function Popup(){
        return (
            <div style={{
                position: "relative"
            }} className="popup-content">
                <img style={{
                    width: `${window.innerHeight > window.innerWidth ? "90vw" : "auto"}`,
                    height: `${window.innerHeight > window.innerWidth ? "auto" : "90vh"}`,
                }}  src={giaReport} alt="DKVK Yellow Diamond GIA Report" />
                <button style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    // zIndex: ""
                }} onClick={hidePopupHandler}
                 className="btn btn-close"/>
            </div>
        )
    }

    const navigate = useNavigate()
    const videoRef = useRef(null)
    useEffect(() => {
        if (videoRef.current) {
            const videoElement = videoRef.current.children[0];
            videoElement.play().then(() => {
            }).catch(() => {

                document.addEventListener("click", () => {
                    if (videoElement.paused) {
                        videoElement.play().then(() => {}).catch(() => {})
                    }
                    document.removeEventListener("click", () => { })

                })
            })
        }


    }, [])
    return (
        <div className="Collection">
            <div className="container text-center">
                <div className="row">
                <div className="col-12 col-lg-6">
                        <h1 className="use-brand-color font-bauhmas93">Dazzling Yellow Diamonds,<br />
                         Unmatched Elegance, <br />
                          Certified Brilliance</h1>
                          <p className="font-artifika h5 mt-4">
                          {/* Elevate your style with the enchanting allure of certified yellow diamonds, ensuring sophistication with every sparkle. */}
                          Wholesalers of Natural Diamond, <br />
                           Solitaires, <br /> 
                           Yellow Diamonds, <br />
                            Uncut Polki Diamonds. <br /> 
                          </p>
                          <button onClick={() => navigate("/contact")} className="btn"><span>Contact Us</span></button>
                        <p className="h5 font-bauhmas93 mt-5">Craftily Diamond Jewellery Since 1995.</p>
                    </div>
                    <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                        <div className="video" ref={videoRef} dangerouslySetInnerHTML={{__html: `
                                                <video 
                                                loop 
                                                muted 
                                                autoplay 
                                                playsinline  
                                                defaultMuted 
                                                >
                                                 <source src=${yellowDiamond}  type="video/mp4" alt="Dkvk Jewellers / Yellow Diamond" />
                                                  </video>
                        `}}>

                          </div>
                          <button onClick={() => showPopupHandler(<Popup />)} className="btn mt-4"><span>View Gia Certificate</span></button>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default Collection;