import "./navbar.scss"
import logo from "./logo.webp"
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Navbar({alwaysCompact}){
  const navbarRef = useRef(null);
  function handleScroll() {
    if (window.scrollY > 200) {
       navbarRef.current.classList.add("compact");
      } else{
        navbarRef.current.classList.remove("compact");
      }

  };

  useEffect(() => {


    if(alwaysCompact) { navbarRef.current.classList.add("compact"); return;}
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line
  }, []);
    return (
        <div className="Navbar">
        <nav ref={navbarRef} className="navbar navbar-expand-lg bg-body-tertiary navbar-dark">
  <div className="container-fluid">
    <a className="navbar-brand " href="/">
        <img src={logo} alt="Dkvk Jewellers" className="logo" />
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse me-auto" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/products">Products</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact us</Link>
        </li>
      </ul>
    </div>
  </div>
</nav>
</div>
    )
}

export default Navbar;