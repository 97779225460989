
import { useState } from "react";
import Navbar from "../../components/navbar/navbar";
import About from "./components/about";
import Collection from "./components/collection";
import Introduction from "./components/introduction";
import "./home.scss"
import Footer from "../../components/footer/footer";

function Home(){
    const [showPopup, setShowPopup] = useState(false);
    const [popup, setPopup] = useState(null);

    function showPopupHandler(popup){
        setPopup(popup);
        setShowPopup(true);
    }

    function hidePopupHandler(){
        setShowPopup(false);
        setPopup(null)
    }

    const popupAttr = {
        showPopupHandler,
        hidePopupHandler
    }

    return (
        <div className="Home">
            <div hidden={!showPopup} className="Popup">
                {popup}
            </div>
            <Navbar />
            <Introduction />
            <About />
            <Collection {...popupAttr} />
            <Footer />
        </div>
    )
}


export default Home;