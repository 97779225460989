import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import "./products.scss";
import product1 from "./images/product1.jpg";
import product2 from "./images/product2.jpg";
import product3 from "./images/product3.jpg";
import product4 from "./images/product4.jpg";
import product5 from "./images/product5.jpg";
import product6 from "./images/product6.jpg";
import product7 from "./images/product7.jpg";
import product8 from "./images/product8.jpg";
import video1 from "./videos/video1.mp4";
import video2 from "./videos/video2.mp4";
import video3 from "./videos/video3.mp4"
import video4 from "./videos/video4.mp4"
import video5 from "./videos/video5.mp4"
export default function Products(){
    const uncut_kundan = [
        {
            img: product1
        },
        {
            img: product2
        },
        {
            img: product3
        },
        {
            img: product4
        },
        {
            img: product5
        },
        {
            img: product6
        },
        {
            img: product7
        },
        {
            img: product8
        }
    ]

    const natural_diamonds = [
        {
            vid: video1
        },
        {
            vid: video2
        },
        {
            vid: video3
        },
        {
            vid: video4
        },
        {
            vid: video5
        }
    ]
    
        return (
        <>
            <Navbar alwaysCompact={true}/>
            <div className="Products">
                <h1 className="font-bauhmas93 use-brand-color text-center"> Products </h1>
                <div className="products container mt-3">
                    <div className="row">
                <h2 className="text-center">Uncut Kundan Jewellery</h2>
                        {uncut_kundan.map((product, index) => (
                            <div key={index} className="product col col-6 col-lg-4 p-3">
                                <img src={product.img} alt={product.name} className="product-img" />
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <h2 className="text-center">Natural Diamonds</h2>
                        {natural_diamonds.map((product, index) => (
                            <div key={index} className="product col col-6 col-lg-4 p-4" dangerouslySetInnerHTML={{
                                __html: `
                                <video 
                                muted 
                                autoplay 
                                playsinline 
                                muted 
                                defaultMuted 
                                class="product-vid"
                                loop
                                >
                                 <source src=${product.vid}  type="video/mp4" alt="Natural diamonds" />
                                  </video>
                                `
                            }}>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer /> 
        </>
    )
}