import { useEffect, useRef, useState } from "react";
import video from "../media/video.mp4";
import thumbnail from "../media/thumbnail.jpg"

function Introduction() {
    
    const videoRef = useRef(null)
    const contentRef = useRef(null);

    const [videoPlayed, setVideoPlayed] = useState(false)
    // Check if it is scrolled more then 100vh AND HIDE content
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', checkScroll);

        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    
    useEffect(() => {
        if (videoRef.current) {
            const videoElement = videoRef.current.children[0];
            videoElement.play().then(() => {
                setVideoPlayed(true)
            }).catch(err => {            })


                document.addEventListener("click", () => {
                        videoElement.play().then(() => setVideoPlayed(true)).catch(() => {})
                    document.removeEventListener("click", () => {})
                })
        }


    }, [])

    return (
        <>
            <div className="Introduction">
                {/* If video isn't played */}
                <div className="image">
                    {!videoPlayed && <img src={thumbnail} alt="Dkvk Jewellers / video" />}
                </div>
                <div className="video" ref={videoRef} style={{
                    display: videoPlayed ? "block" : "none"
                }} dangerouslySetInnerHTML={{
                    __html: `
                <video 
                muted 
                autoplay 
                playsinline 
                muted 
                defaultMuted 
                loop
                poster="${thumbnail}"  
                >
                 <source src=${video}  type="video/mp4" alt="Dkvk Jewellers / video" />
                  </video>
        `}}>
                </div>
                <div className="container text-center" ref={contentRef} hidden={isScrolled}>
                    <div className="content">
                        <h1 className="font-bauhmas93"> <span className="shimmer">Sparkle Worldwide with</span> <span className="use-brand-color">Certified Jewelry</span></h1>
                        <p className="font-artifika mt-3">Discover stunning jewelry,  <br />
                            Shipped globally and certified by GIA. <br />
                            Elevate your style with brilliance from <br />
                            <span className="font-bauhmas93 use-brand-color">DKVK Jewellers /  India</span></p>
                            <span className="since-1995 font-bauhmas93 use-brand-color">Since 1995</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Introduction;